<template>
  <div>
    <b-row v-for="element in elements" :key="element.id">
      <b-col md="12">
        <bruna-element
          v-if="element"
          :id="element.id"
          :name="element.name"
          :component="element.data.component"
          :wrapper_component="element.data.wrapper_component"
          
        > 
        </bruna-element>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
} from "bootstrap-vue";

import BrunaElement from "@/views/brunacomponents/BrunaElement.vue";

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody, 
    BCard,
    VBPopover,
    BrunaElement,
  },

  props: {
    elements: {
      type: Array,
      default: [],
    },
  },

  directives: {
    "b-popover": VBPopover,
  },

  data: function () {
    return {};
  },
  created() {},

  mounted() {},

  methods: {},
};
</script>

<style lang="scss"></style>
